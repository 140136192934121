<template>
    <div>
        <div class="bg-white">
            <div class="topbox flex align-center justify-between">
                <div class="flex align-center">
                    <img src="/tempimg/logo.png" alt="">
                    <div class="menu flex align-center margin-left">
                        <span class="on" @click="$router.push('/user/tempIndex')">首页</span>
                        <span @click="$router.push('/user/index')">就业通</span>
                    </div>
                </div>
                <div class="flex align-center">
                    <div class="flex align-center">
                        <div v-if="userInfo && roleId == 99" class="flex align-center" style="margin-right:15px;">
                            <!-- <span>{{ userInfo.userName }}</span> -->
                            <el-dropdown>
                                <span class="el-dropdown-link">
                                {{ userInfo.userName }} <i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                <!--                                <div @click="routeTo('/user/resumes')"><el-dropdown-item><i class="el-icon-s-home"></i> 基本信息</el-dropdown-item></div>-->
                                <div @click="routeTo('/user/resumes')">
                                    <el-dropdown-item
                                    ><i class="el-icon-s-home"></i> 我的简历</el-dropdown-item
                                    >
                                </div>
                                <div @click="routeTo('/user/settingInfo')">
                                    <el-dropdown-item
                                    ><i class="el-icon-s-tools"></i>
                                    账号设置</el-dropdown-item
                                    >
                                </div>
                                <div @click="$router.push({ path: '/user/realname' })">
                                    <el-dropdown-item
                                    ><i class="el-icon-user-solid"></i>
                                    实名认证</el-dropdown-item
                                    >
                                </div>
                                <div @click="$router.push({ path: '/user/smartjob' })">
                                    <el-dropdown-item
                                    ><i class="el-icon-user-solid"></i>
                                    智能岗位推荐</el-dropdown-item
                                    >
                                </div>
                                <div @click="routeTo('/user/videohome')">
                                    <el-dropdown-item
                                    ><i class="el-icon-video-camera-solid"></i>
                                    视频面试</el-dropdown-item
                                    >
                                </div>
                                <div @click="routeTo('/user/sendlist')">
                                    <el-dropdown-item
                                    ><i class="el-icon-s-comment"></i>
                                    消息管理</el-dropdown-item
                                    >
                                </div>
                                <div @click="userLogout">
                                    <el-dropdown-item
                                    ><i class="el-icon-message-solid"></i
                                    >退出登录</el-dropdown-item
                                    >
                                </div>
                                </el-dropdown-menu>
                            </el-dropdown>

                        </div>
                        <div v-else-if="userInfo && roleId == 2">
                            <span class="cursor-pointer" @click="$router.push({path:'/company/mine',query:{needAuthCheck:true}})">企业中心</span>
                        </div>
                        <template v-else>
                            <img src="@/assets/img/newIndex/user.png" style="width:15px;" alt="" class="menu-icon" />
                            <a class="menu-item cursor-pointer" @click="$router.push('/newUserLogin')">个人登录</a>
                            <img src="@/assets/img/newIndex/enterprice.png" style="width:15px; margin-left:15px;" alt="" class="menu-icon" />
                            <a class="menu-item cursor-pointer" @click="$router.push('/newLogin')">企业登录</a>
                        </template>
                        <el-popover
                            placement="bottom"
                            title=""
                            style="margin-left: 15px;"
                            width="330"
                            trigger="hover">
                            <div class="downbox">
                                <div class="flex align-center justify-between">
                                    <div class="downleft">
                                        <a target="_blank" href="https://apps.apple.com/cn/app/%E5%B9%BF%E4%BF%A1%E5%B0%B1%E4%B8%9A%E9%80%9A/id1659608820" class="downleftitem flex align-center">
                                            <i class="iconfont icon-ios"></i>
                                            <span>IOS下载</span>
                                        </a>
                                        <a target="_blank" href="http://az.srdxg.com/4tz2?utm_source=fir&utm_medium=qr" class="downleftitem downleftitem2 flex align-center">
                                            <i class="iconfont icon-anzhuo"></i>
                                            <span>安卓下载</span>
                                        </a>
                                    </div>
                                    <div class="downright"><img style="width:120px;height: 120px;" src="http://data.srgxjy.com/downerw.png" alt="" class="qr" /></div>
                                </div>
                            </div>
                            <div class="flex align-center" slot="reference"><i style="color: #007dff;" class="iconfont icon-shouji"></i>app下载</div>
                        </el-popover>
                    </div>
                </div>
            </div>
        </div>
        <div class="banner">
            <img src="/img/banner.png" width="100%" alt="">
        </div>
        <div class="tempbox" style="padding-bottom: 30px;">
            <div class="flex align-center">
                <strong class="text-xxl">热门招聘大厅</strong>
                <div class="searchbox flex align-center bg-white justify-between">
                    <input type="text" placeholder="关键词搜索" class="topsearchinput padding-left-xs" v-model="keyword">
                    <span class="flex cursor-pointer text-white align-center justify-center" @click="topSearch"><i class="el-icon-search margin-right-xs"></i>搜索</span>
                </div>
            </div>

            <div class="jobbox margin-top-sm">
                <div class="zpdtitem text-white flex align-center justify-center text-xl text-bold radius padding-sm cursor-pointer" v-for="(item,index) in hotJobList" @click="gotoNewJob(item)">
                    {{ item }}
                </div>
            </div>

            <div class="flex align-center margin-top">
                <strong class="text-xxl">高级技能岗位</strong>
            </div>

            <div class="jobbox margin-top-sm">
                <div class="zpdtitem text-white flex align-center justify-center text-xl text-bold radius padding-sm cursor-pointer" v-for="(item,index) in hotJobList2" @click="gotoNewJob(item)">
                    {{ item }}
                </div>
            </div>
<!--            <div class="flex justify-center">-->
<!--                <span class="btnspan text-white margin-top cursor-pointer radius align-center justify-center text-lg" @click="$router.push('/user/searchJobs')">更多类似岗位</span>-->
<!--            </div>-->
            <div class="text-bold text-xxl margin-top-lg">企业单位</div>
            <div class="companylist margin-top-sm" v-loading="loading">
                <div class="companyitembox bg-white radius cursor-pointer" v-for="(item,index) in member" @click="gotoCompany(item)">
                    <div class="companyitemtop flex align-center padding-xs">
                        <div style="flex: 0 0 44px;">
                            <el-avatar shape="square" :size="44"
                                       :src="item.logo?item.logo:'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'"></el-avatar>
                        </div>

                        <div class="margin-left-xs text-white">
                            <div class="text-cut text-lg">{{ item.companyName }}</div>
                            <div class="text-xs margin-top-xs">{{ item.company }} | {{ item.companyNatureName }}</div>
                        </div>
                    </div>
                    <div class="padding-sm">
                        <div class="margin-bottom-sm" v-for="(sonitem,sonindex) in item.workList" v-if="sonindex<=1">
                            <div class="flex align-center justify-between text-lg" >
                                <strong class="text-cut text-black" style="width: 160px;">{{ sonitem.workName }}</strong>
                                <strong class="text-black text-xs">{{ sonitem.workEducation }}</strong>
                            </div>
                            <div class="flex align-center justify-between text-xs margin-top-xs" style="color: #3e3e3e;">
                                <span>{{ sonitem.workYears }}</span>
                                <span style="width: 145px;" class="text-cut">{{ sonitem.address }}</span>
                            </div>
                        </div>

                        <div class="flex align-center justify-center">
                            <span class="jobtotal padding-tb-xs padding-lr-sm radius" style="color: #00a196; border: #00a196 solid 1px;">共{{ item.workList.length }}个职位</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center">
                <span class="btnspan text-white margin-top radius align-center cursor-pointer justify-center text-lg" @click="$router.push('/user/companyList')">更多类似岗位</span>
            </div>
            <div class="text-bold text-xxl margin-top-lg">国有事业单位</div>
            <div class="stateowned margin-top-sm" v-loading="loading">
                <div class="sydwitem radius padding-sm cursor-pointer flex align-center" v-for="(item,index) in guo" @click="gotoCompany(item)">
                    <div style="flex: 0 0 80px;">
                        <el-avatar shape="square" :size="80"
                                   :src="item.logo?item.logo:'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'"></el-avatar>
                    </div>
                    <div class="margin-left-xs" style="overflow: hidden;">
                        <div class="text-cut text-lg" >{{ item.companyName }}</div>
                        <div class="text-xs margin-top-xs" style="color: #3e3e3e;">{{ item.company }}</div>
                        <div class="margin-top-sm"><span style="color: #00a196;">{{ item.workList.length }}</span>个热招职位</div>
                    </div>
                </div>
            </div>
            <div class="text-bold text-xxl margin-top-lg">外出务工企业</div>
            <div class="stateowned margin-top-sm" v-loading="loading">
                <div class="sydwitem radius padding-sm cursor-pointer flex align-center" v-for="(item,index) in out" @click="gotoCompany(item)">
                    <div style="flex: 0 0 80px;">
                        <el-avatar shape="square" :size="80"
                                   :src="item.logo?item.logo:'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'"></el-avatar>
                    </div>
                    <div class="margin-left-xs" style="overflow: hidden;">
                        <div class="text-cut text-lg" >{{ item.companyName }}</div>
                        <div class="text-xs  margin-top-xs" style="color: #3e3e3e;">{{ item.company }}</div>
                        <div class="margin-top-sm"><span style="color: #00a196;">{{ item.workList.length }}</span>个热招职位</div>
                    </div>
                </div>
            </div>
            <div class="text-bold text-xxl margin-top-lg">乡镇平台</div>
            <div class="stateowned margin-top-sm" >
                <div class="sydwitem radius padding-sm cursor-pointer flex align-center" v-for="(item,index) in township" @click="gotoCompany2(index,item)">
                    <div style="flex: 0 0 80px;">
                        <el-avatar shape="square" :size="80"
                                   :src="'/img/jd/'+(index+1)+'.png'"></el-avatar>
                    </div>
                    <div class="margin-left-xs" style="overflow: hidden;">
                        <div class="text-cut text-lg" >{{ item }}</div>
<!--                        <div class="text-xs  margin-top-xs" style="color: #3e3e3e;">{{ item.company }}</div>-->
<!--                        <div class="margin-top-sm"><span style="color: #00a196;">{{ item.workList.length }}</span>个热招职位</div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import userRequest from '@/api/user';
export default{
    data(){
        return{
            keyword:null,
            indexSearch: {
                pageSize: 9,
                pageNum: 1,
            },
            township:['旭日街道', '罗桥街道', '田墩镇', '上泸镇', '华坛山镇', '茶亭镇', '皂头镇', '四十八镇', '枫岭头镇', '煌固镇', '花厅镇', '五府山镇', '郑坊镇', '望仙乡', '石人乡', '清水乡', '湖村乡', '尊桥乡', '应家乡', '黄沙岭乡', '铁山乡'],
            hotJobList:["服务员","零工","水电工","木工","油漆工","厨师","门卫","保洁员","传菜员","采购员","普工","快递员"],
            hotJobList2:["会计","Java","后端开发","全栈工程师","测试工程师","产品经理","前端开发","UI设计师","交互设计师","淘宝运营","媒介策划","行政总监"],
            member:[],
            guo:[],
            out:[],
            loading:false,
            roleId:null,
            userInfo:null
        }
    },
    created() {
        let roleId = Number(window.localStorage.getItem('roleId'));
        let user=localStorage.getItem('userInfo')
        if(user){
            this.userInfo=JSON.parse(user)
        }
        this.roleId = roleId;
        this.getData()
    },
    //创建前设置
    beforeCreate () {
        // 例如设置为红色
        document.querySelector('body').setAttribute('style', 'background-color: #f2f3f3;')
    },
    methods:{
        topSearch(){
            if(!this.keyword){
                this.$message.warning("请输入关键词！")
                return
            }

            this.$router.push({path:"/user/newJobList",query:{key:this.keyword}})

        },
        routeTo(path) {
            if (path === this.$route.path) {
                return;
            }
            this.$router.push({
                path: path,
            });
        },
        gotoNewJob(key){//去新的职位搜索页
            this.$router.push({path:"/user/newJobList",query:{key:key}})
        },
        getData(){
            this.loading=true
            userRequest.getIndexData(this.indexSearch).then(res => {
                this.loading=false
                console.log(res)
                if(res.code===200){
                    // this.hotJobList = res.data.works;
                    this.member=res.data.member
                    this.guo=res.data.guo
                    this.out=res.data.out
                }
            })
        },
        gotoJobDetail(item){
            ///user/jobDetail?workId=78400&workName=装配工\
            console.log(item)
            // return
            this.$router.push({path:"/user/jobDetail",query:{workId:item.id,workName:item.workName}})
        },
        gotoCompany(item){
            this.$router.push({path:"/user/companyJobs",query:{companyId:item.id}})
        },
        gotoCompany2(index,item){
            this.$router.push({path:"/tempCompanyList",query:{index:index,name:item}})
        },
        userLogout() {
            this.$confirm('是否确定退出登录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    userRequest
                        .userLogout()
                        .then(res => {
                            if (res.code === 200) {
                                window.localStorage.setItem('userInfo', JSON.stringify(''));
                                window.localStorage.setItem('token', JSON.stringify(''));
                                this.$message.success('退出成功~');
                                // this.$router.push({ path: '/' });
                                location.reload()
                            }
                        })
                        .catch(err => {
                            this.$message.error(err);
                        });
                    // this.$message({
                    //     type: 'success',
                    //     message: '删除成功!'
                    // })
                })
                .catch(() => {});
        },
    }
}
</script>
<style scoped>
.topbox{ height: 56px; width: 1180px; margin: 0 auto;}
.menu span{ display: block;padding: 0 16px; cursor: pointer;}
.menu span.on{color: #1678ff;}
.tempbox{ width: 1180px; margin: 0 auto 0 auto;}
.jobsearch{ border-radius: 5px;}
.jobsearch span{ padding: 15px 17px; cursor: pointer;}
.jobsearch span:hover{color: #1678ff;}
.jobsearch span.on{ position: relative;color: #1678ff;}
.jobsearch span.on:after{content: ''; position: absolute; width: 80%; height: 3px; background: #1678ff; bottom: 0; left: 10%;}
.jobbox{ display: grid;grid-template-columns:  repeat(4,283px);grid-template-rows:  repeat(2,136px);column-gap:16px ;row-gap: 16px;}
span.btnspan{ display: flex; width: 316px; height: 44px; background: #1678ff;}
.companylist{display: grid;grid-template-columns:  repeat(4,283px);grid-template-rows:  repeat(1,257px);column-gap:16px ;row-gap: 16px;}
.companyitemtop{ height: 74px; background: #00a196;}
.companylist div{ overflow: hidden;}
span.jobtotal { display: block; border: #2a82e4 solid 1px; color: #2a82e4;}
.stateowned{display: grid;grid-template-columns:  repeat(4,283px);grid-template-rows:  repeat(2,120px);column-gap:16px ;row-gap: 16px;}
.banner{margin-bottom: 30px;}
.banner img{ display: block;}

.searchbox{ width: 680px; margin-left: 50px; height: 40px; border: #1678ff solid 2px; border-radius: 8px; overflow: hidden;}
.topsearchinput{ outline: none; border: none; width: 500px; height: 36px;}
.searchbox span{ display: flex; height: 36px; width: 100px; background: #1678ff;}
.zpdtitem{ width: 283px; height: 136px; background: url("../../../assets/zpdtbg.png") no-repeat top left;}
.companyitembox{background: url("../../../assets/companybg.png") no-repeat top left;}
.sydwitem{background: url("../../../assets/sydwbg.png") no-repeat top left;}


</style>


